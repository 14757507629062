<template>
  <div v-if="!hide && enabled && debugData?.sentry.overlay">
    <div
      class="fixed bottom-0 left-xs opacity-50 text-sm z-[99999] flex-row flex flex-nowrap w-full gap-x-xs"
    >
      <div>sId: {{ sentryUserId }} | ImgId: {{ image }}</div>
      <div
        v-if="!replayHasBeenSent && !isLoading"
        class="cursor-pointer text-primary-dark"
        @click="sendSentryReplay"
      >
        [ > Replay erstellen 📼]
      </div>
      <div v-else-if="isLoading" class="text-gray">
        [ > Replay wird gesendet ✉️ ]
      </div>
      <div v-else class="text-status-success-dark">
        [ > Replay gesendet! ✔️ ]
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UseStateKeys } from '@/useStateKeys';
import * as Sentry from '@sentry/vue';
import useGetDebugData from '../useGetDebugData';

const enabled =
  useRuntimeConfig().public.ENVIRONMENT_NAME?.toLowerCase() === 'staging' ||
  useRuntimeConfig().public.ENVIRONMENT_NAME === 'local';

const debugData = useGetDebugData();
const hide = useCookie('hide_sentry_user_id');
const sentryUserId = useState(UseStateKeys.SENTRY_USER_ID);
const image = useRuntimeConfig().public.IMAGE_VERSION;

const replayHasBeenSent = ref(false);
const isLoading = ref(false);

async function sendSentryReplay() {
  isLoading.value = true;
  await Sentry.getReplay().flush();
  replayHasBeenSent.value = true;
  isLoading.value = false;
}
</script>
